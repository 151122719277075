import * as Sentry from "@sentry/vue";
/*
    This mixin takes care of setting up the user context for the various Sentry integrations
    to annotate any caught exceptions, error messages, etc with data about the currently authenticated
    user, if there is one.
 */
export default {
    beforeCreate() {
        if(typeof(user) !== 'undefined' && user != null) {
            Sentry.setUser({
                id: user.customerId,
                ip_address: "{{auto}}",
                professional: user.professional,
                retail: user.retail
            })
        }
    },

    destroyed() {
        Sentry.setUser(null)
    }
}