import autofocus from "vue-autofocus-directive";
import Buefy from "buefy"; // Leave this, it makes Buefy work everywhere
import Vue2Filters from 'vue2-filters';
import format from 'date-fns/format';
import {enGB, enUS, enCA, enIN, } from 'date-fns/locale';
import cdnHelper from "~/mixins/cdn-helper";
import * as Sentry from "@sentry/vue";
import { CaptureConsole, HttpClient } from "@sentry/integrations";
import sentryUserProvider from "~/mixins/sentry-user-provider";


//Register global attributes to be present in all vue components
Vue.prototype.isLoggedIn = loggedIn;
Vue.prototype.cdnBase = cdnBase;

const dateFormatLocales = {
	"en_US": enUS,
	"en_GB": enGB,
	"en_IN": enIN,
	"en_CA": enCA,
};

Vue.use(Vue2Filters);

// CDN url helper mixin
Vue.mixin(cdnHelper);

// Configure Buefy
Vue.prototype["$buefy"].config.setOptions({
	defaultIconPack: "fal",
	customIconPacks: {
		"fas": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		},
		"fal": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		},
		"far": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		}
	}
});

/*
	Groups like pages so that we can obtain overall performance metrics for the group
	rather than for each unique URL
 */
const pathPrefixesToGroup = [
	'/content/blocks/',
	'/health-professionals/',
	'/products/dp/',
	'/products/by-ingredient/',
	'/quizzes/',
	'/take-5-daily/article/',
	'/take-5-daily/video/',
	'/take-5-daily/podcast/',
	'/u/'
]
function sanitizeUrlPath() {
	let matchIx = pathPrefixesToGroup.findIndex(prefix => location.pathname.startsWith(prefix))
	return matchIx >= 0 ? `${pathPrefixesToGroup[matchIx]}*` : location.pathname
}

// Configure Sentry logging
const sEnv = typeof (sentryEnv) !== 'undefined' && sentryEnv != null ? sentryEnv : 'unknown'
const sDsn = typeof (sentryDsn) !== 'undefined' && sentryDsn != null ? sentryDsn : null
const sSampleRate = typeof (sentrySampleRate) !== 'undefined' && sentrySampleRate != null ? sentrySampleRate : 0
const sAllowUrls = sEnv === 'dev' ? null : [
	/https:\/\/(.*)\.thorne\.com/,
	"https://d1xi6p3t9cypgw.cloudfront.net",
	"https://d1vo8zfysxy97v.cloudfront.net"
]

// We'll use the presence of the sentry DSN as our clue to whether we want to enable sentry or not
// const sentryEnabled = sDsn != null
const sentryEnabled = false  // temporarily disabling as we have way too much noise and it's eating up our spend allocation
if(sentryEnabled) {
	// Set up user context, if present, for inclusion in sentry logging
	Vue.mixin(sentryUserProvider);

	Sentry.init({
		Vue,
		dsn: sDsn,
		environment: sEnv,
		integrations: [
			// Report any console.error(...) messages
			new CaptureConsole({ levels: ['error'] }),

			// Report any XHR or fetch responses with status code in 500-599 range
			// For now, do this for ANY backend service.. can whitelist just hosts / endpoints we care about in the future if too noisy
			new HttpClient({ failedRequestStatusCodes: [[500, 599]] }),

			// Add performance tracing for page loads and navigation changes
			new Sentry.BrowserTracing({
				beforeNavigate: (context) => {
					return {
						...context,
						// If we used vue-router everywhere in the app we could use the route names
						// but instead we do our own basic sanitization to try and group like urls together
						name: sanitizeUrlPath(),
					};
				},
			})
		],

		// track component lifecycle performance
		trackComponents: true,

		// Trace all transactions in staging/dev, limiting to only 5% in prod to begin with .. worried about load and cost
		tracesSampleRate: sSampleRate,

		// Only send errors that originate from the allowed urls list
		allowUrls: sAllowUrls
	});
}

//Set some axios defaults
axios.defaults.headers.common = {
	"Content-Type": "application/json",
	"X-Requested-With": "XMLHttpRequest",
	"X-CSRF-TOKEN": document.querySelector("meta[name='_csrf']").getAttribute("content")
};

// Create floating label directive
Vue.directive("floating-label", {
    inserted: function (el) {
        // Add a class for styling
        el.classList.add("has-floating-label");

        // Move the label after the input (so we can do pure CSS floating label styling)
        const label = el.querySelector("label");
        let input = el.querySelector("input");
        if (!input) {
        	input = el.querySelector("textarea");
        }
        if (!input) {
        	input = el.querySelector("select");
        }

        if (label && input) {
        	input.parentElement.insertBefore(label, input.nextSibling);

        	// Because I'm lazy and don't want to have to set the invisible placeholder all the time, copy the label into the placeholder
        	input.placeholder = label.textContent;
        }

    }
});

//Add a filter allowing to format the currency based on locale
Vue.filter("toCurrency", function (value) {
	let result = value;
    if (!isNaN(parseFloat(value))) {
    	let loc = pageLocale.replace("_", "-");
    	var formatter = new Intl.NumberFormat(loc, {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0
        });
        result = formatter.format(value);
    }
    return result;
});

//Add a filter allowing to format a date based on locale and/or format
Vue.filter("dateFormat", function (value, defaultResult, dtFormat) {
	let result = defaultResult;
	if (value) {
		let tmpDate = new Date(value);
		if (tmpDate !== "Invalid Date" && !isNaN(tmpDate)) {
			let loc = pageLocale.replace("_", "-");
			const dateFnsLocale = dateFormatLocales[pageLocale];
	    	if (dtFormat && dateFnsLocale) {
				result = format(tmpDate, dtFormat, {locale: enUS});
	    	} else {
	    		result = tmpDate.toLocaleDateString(loc);
	    	}
		}
	}
    return result;
});

/*
* This component is useful for rendering inline html, without including a wrapper element.
* For example, say we had the following html content:
*
* <span>Some cool stuff here</span>
*
*
* Using the following would insert the div into the DOM as well:
*
* <div v-html="content"></div>
* ... becomes ...
* <div><span>Some cool stuff here</span></div>
*
*
* This component allows us to include the html content without a wrapper:
*
* <html-fragment :html="content"></html-fragment>
* ... becomes ...
* <span>Some cool stuff here</span>
*
*
*/
Vue.component('html-fragment', {
	functional: true,
	props: ['html'],
	render(h, ctx) {
		if (ctx.props.html) {
			const html = ctx.props.html.trim();
			const nodes = new Vue({
				beforeCreate() {
					this.$createElement = h
				},
				template: `<div>${html}</div>`
			}).$mount()._vnode.children;
			return nodes;
		}
	}
});

// Global components
Vue.directive("autofocus", autofocus);