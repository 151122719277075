export default {
    methods: {
        $cdn: function (url) {
            const cdnTarget = this.cdnBase || cdnBase;
            let result = url;
            if (url && !url.startsWith("http")) {
                result = url.replace("cdn://", "/").replace("//", "/");
                if (cdnTarget) {
                    result = cdnTarget + result;
                }
            }
            return result;
        }
    }
}